import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Snackbar, Alert, Box, CircularProgress, Backdrop, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axiosInstance from '../api/axiosInstance';

const Regular = () => {
  const { usage, sid } = useParams();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post('/add-entry', {
        name: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        usage: usage || 'REGULAR',
        sid: sid || ''
      });
      console.log(response);
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(err.response.data.error);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const code = "CMFSL001";

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setMessage('Code copied to clipboard!');
      setOpen(true);
      // alert('Code copied to clipboard!');
    });
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        maxWidth: { xs: '100%', md: '800px' }
      }}
    >
      <Box
        component="img"
        src="/comfort-logo.png"
        alt="Logo"
        sx={{
          height: '64px',
          marginBottom: '16px'
        }}
      />
      <Typography variant="h1" component="h1" gutterBottom>
        Comfort Sri Lanka
      </Typography>
      {!success ? (
        <>
          <Typography variant="body1" gutterBottom>
            Please fill out the form below to receive your Comfort Delivery
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              inputProps={{ maxLength: 12, minLength: 10 }}
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              type="email"
            />
            {/* <TextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              required
            /> */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: '16px' }}
            >
              Submit
            </Button>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '32px'
          }}
        >
          <CheckCircleIcon color="success" sx={{ fontSize: '48px', marginBottom: '16px' }} />
          <Typography variant="h6" align="center">
            Thank you for submitting your details! Here’s your Coupon Code.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f6f6f6',
              padding: '8px 16px',
              borderRadius: '4px',
              marginTop:'24px'
            }}
          >
            <Typography variant="h5" component="span" sx={{ marginRight: '8px' }}>
              {code}
            </Typography>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={handleCopy} aria-label="copy">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Regular;
