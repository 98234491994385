import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const Home = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post('/login', {
        loginInfo: {
          email: formData.email,
          password: formData.password
        }
      });

      const { firstName, token } = response.data.user;
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('token', token);

      navigate('/dashboard');
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          width: { lg: '40%', md: '90%', sm: '90%' },
          padding: '32px',
          boxShadow: 1,
          borderRadius: '8px',
          textAlign: 'center'
        }}
      >
        <Box sx={{ marginBottom: '32px' }}>
          {/* Placeholder for Logo */}
          <img src="comfort-logo.png" alt="Logo" style={{ maxHeight: '64px' }} />
        </Box>
        {/* <Typography variant="h4" component="h1" gutterBottom>
          Comfort Bot Admin
        </Typography> */}
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default Home;