import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
    CircularProgress,
    Backdrop,
    Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SyncIcon from '@mui/icons-material/Sync';
import axiosInstance from '../api/axiosInstance';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const Dashboard = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [usageFilter, setUsageFilter] = useState('');
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchEntries();
    }, []);

    const fetchEntries = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/get-entries');
            setEntries(response.data.entries);
        } catch (err) {
            setError('Failed to fetch entries.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = async (event) => {
        const value = event.target.value;
        setUsageFilter(value);
        setLoading(true);
        try {
            const response = await axiosInstance.post('/filter-entries', { usage: value });
            setEntries(response.data.filtered);
        } catch (err) {
            setError('Failed to filter entries.');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src="comfort-logo.png" alt="Logo" style={{ maxHeight: '40px', marginRight: '16px' }} />
                        {/* <Typography variant="h6">Dashboard</Typography> */}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Container sx={{ marginTop: '24px', minWidth: '100%'}}>
                <Box sx={{ width: '50%' }}>
                    <Stack direction="row" spacing={1} sx={{ alignItems:'center' }}>
                        <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                            <InputLabel id="usage-filter-label">Usage</InputLabel>
                            <Select
                                labelId="usage-filter-label"
                                value={usageFilter}
                                label="Usage"
                                onChange={handleFilterChange}
                            >
                                <MenuItem value="USAGE_REGULAR">Regular</MenuItem>
                                <MenuItem value="USAGE_SOMETIMES">Sometimes</MenuItem>
                                <MenuItem value="USAGE_USED_BEFORE">Used Before</MenuItem>
                                <MenuItem value="USAGE_NEVER">Never Used</MenuItem>
                            </Select>
                        </FormControl>
                        
                        <IconButton onClick={fetchEntries} sx={{ backgroundColor: 'white !important' }} color="outline" aria-label="add to shopping cart">
                            <SyncIcon sx={{ backgroundColor:'white !important' }} />
                        </IconButton>
                    </Stack>
                </Box>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Phone</StyledTableCell>
                                <StyledTableCell>Address</StyledTableCell>
                                <StyledTableCell>Usage</StyledTableCell>
                                <StyledTableCell>FB_SID</StyledTableCell>
                                <StyledTableCell>Added On</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry) => (
                                <TableRow key={entry._id}>
                                    <TableCell>{entry.name}</TableCell>
                                    <TableCell>{entry.lastName}</TableCell>
                                    <TableCell>{entry.email}</TableCell>
                                    <TableCell>{entry.phone}</TableCell>
                                    <TableCell>{entry.address}</TableCell>
                                    <TableCell>{entry.usage}</TableCell>
                                    <TableCell>{entry.sid}</TableCell>
                                    <TableCell>{format(new Date(entry.createdOn), 'yyyy-MM-dd HH:mm')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default Dashboard;
