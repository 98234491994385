import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Regular from './pages/Regular';
import NonRegular from './pages/NonRegular';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { Container } from '@mui/material';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <Router>
      <Container sx={{
        minWidth: '100%',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/regular/:usage/:sid" element={<Regular />} />
          <Route path="/non-regular/:usage/:sid" element={<NonRegular />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Container>
    </Router>

  );
}
export default App;
